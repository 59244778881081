import "./NavbarStyles.css";
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from "../assets/volverLogo.png";

const Navbar = () => {
    const [activeNav, setActiveNav] = useState('');
    const [click, setClick] = useState(false);
    const [color, setColor] = useState(false);
    const location = useLocation();

    const changeColor = () => {
        if (window.scrollY >= 1) {
            setColor(true);
        } else {
            setColor(false);
        }
    };

    useEffect(() => {
        const path = location.pathname.substring(1); // Remove o caractere /
        setActiveNav(path === "" ? "home" : path);
    }, [location]);

    useEffect(() => {
        window.addEventListener("scroll", changeColor);
        return () => {
            window.removeEventListener("scroll", changeColor);
        };
    }, []);

    const handleOnClick = (id) => {
        setActiveNav(id);
        setClick(false); // Fecha o menu após clicar em um item
    };

    return (
        <>
            <div className={color ? "nav nav-bg" : "nav"}>
                <Link to="/" onClick={() => handleOnClick('home')}>
                    <img className="logo" src={Icon} alt="Logo" width={70} height={65} />
                </Link>

                <ul id="navbar" className={click ? "navbar active" : "navbar"}>
                    <li>
                        <Link
                            to="/"
                            onClick={() => handleOnClick('home')}
                            className={activeNav === 'home' ? 'active' : ''}
                        >
                            Início
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/fisioterapeutas"
                            onClick={() => handleOnClick('fisioterapeutas')}
                            className={activeNav === 'fisioterapeutas' ? 'active' : ''}
                        >
                            Fisioterapeutas
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/contatos"
                            onClick={() => handleOnClick('contatos')}
                            className={activeNav === 'contatos' ? 'active' : ''}
                        >
                            Contatos
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/feedbacks"
                            onClick={() => handleOnClick('feedbacks')}
                            className={activeNav === 'feedbacks' ? 'active' : ''}
                        >
                        Avaliações
                        </Link>
                    </li>
                </ul>

                <div id="mobile" onClick={() => setClick(!click)}>
                    <i id="bar" className={click ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
            </div>
        </>
    );
}

export default Navbar;
