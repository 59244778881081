import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import './PhysiotherapistsStyles.css';

import Ana_Claudia from '../assets/fisios/anaclaudia.png'; // Substitua com o caminho correto para as fotos
import Ana_Luiza from '../assets/fisios/analuiza.png';
import Victor from '../assets/fisios/victor.png';

const physiotherapists = [
    {
        name: 'Dra. Ana Cláudia H. Carrijo',
        education: 'Formada em Fisioterapia pela UFU',
        pos: "Pós-graduação em Traumato-Ortopédica e Esportiva pela Faculdade Inspirar, especializações complementares em Dry Needling e Pilates.",
        description: 'Dra. Ana Cláudia, proprietária da Volver ProFisio, dedica-se a oferecer tratamentos de altíssima qualidade, garantindo a recuperação completa de seus pacientes e a prevenção de futuras lesões. Com destacada atuação em fisioterapia esportiva, fisioterapia neurológica, traumato-ortopédica, prevenção de lesões, pré e pós-operatório e gerontologia, ela aplica seu vasto conhecimento e experiência para proporcionar cuidados personalizados e eficazes.',
        photo: Ana_Claudia
    },
    {
        name: 'Dra. Ana Luiza R. Pacheco',
        education: 'Formada em Fisioterapia pela UFU',
        pos: 'Cursando Pós-graduação em Traumatologia e Ortopedia pela faculdade Metropolitana, especialização complementar em Pilates.',
        description: 'Dra. Ana Luiza é uma fisioterapeuta com experiência em atendimentos voltados para a gerontologia e ortopedia. Seu compromisso é proporcionar cuidados especializados e humanizados, focando na recuperação, manutenção e melhoria da qualidade de vida de seus pacientes.',
        photo: Ana_Luiza,
    },
    {
        name: 'Dr. Victor Rodholfo O. Silva',
        education: 'Formado em Fisioterapia pela UFU',
        pos: 'Mestrando em Fisioterapia pela UFU, especializações complementares em Quiropraxia Clínica, Biomecânica Aplicada, Análise da Biomecânica da Corrida e Dry Needling.',
        description: 'Dr. Victor é dedicado a utilizar práticas fundamentadas em evidências científicas, assegurando as melhores estratégias de tratamento para atender as necessidades específicas de cada paciente.',
        photo: Victor
    }
];

const PhysiotherapistsContent = () => {
    return (
        <section id="physiotherapists">
            <Helmet>
                <title>Volver ProFisio | Fisioterapia & Recovery</title>
                <link rel="canonical" href="https://www.volverprofisio.com.br/fisioterapeutas" />
                <meta name="description" content="Conheça a equipe da Volver ProFisio. Nossos profissionais são especializados em diversas áreas da fisioterapia, oferecendo tratamentos personalizados e de alta qualidade para atender às suas necessidades" />
            </Helmet>
            <div className="physiotherapists-container">
                <div className="physiotherapists-grid">
                    {physiotherapists.map((therapist, index) => (
                        <motion.div
                            key={index}
                            className="therapist-card"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.3 }}
                        >
                            <img src={therapist.photo} alt={`${therapist.name} - Fisioterapeuta em Uberlândia`} className="therapist-photo" />
                            <h2 className="therapist-name">{therapist.name}</h2>
                            <p className="therapist-education">{therapist.education}</p>
                            <p className="therapist-pos">{therapist.pos}</p>
                            <span className="divider"></span>
                            <p className="therapist-description">{therapist.description}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PhysiotherapistsContent;
