import React, { useEffect, useRef } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import "./index.css";
import HomeContent from "./components/HomeContent";
import ContactContent from "./components/ContactContent";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import WhatsAppContent from "./components/WhatsAppContent";
import FeedbackContent from "./components/FeedbackContent";
import PhysiotherapistsContent from "./components/PhysiotherapistsContent";

function App() {
  const location = useLocation();
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const infoRef = useRef(null);
  const contactRef = useRef(null);
  const feedbackRef = useRef(null);
  const physioRef = useRef(null);

  useEffect(() => {
    const path = location.pathname;
    const scrollToSection = () => {
      switch (path) {
        case "/":
          homeRef.current.scrollIntoView({ behavior: "smooth" });
          break;
        case "/fisioterapeutas":
          physioRef.current.scrollIntoView({ behavior: "smooth" });
          break;
        case "/contatos":
          contactRef.current.scrollIntoView({ behavior: "smooth" });
          break;
        case "/feedbacks":
          feedbackRef.current.scrollIntoView({ behavior: "smooth" });
          break;
        default:
          homeRef.current.scrollIntoView({ behavior: "smooth" });
          break;
      }
    };
    scrollToSection();
  }, [location]);

  return (
    <div className="App">
      <div className="background-overlay"></div>
      <WhatsAppContent />
      <Navbar />
      <div ref={homeRef} id="home">
        <HomeContent />
      </div>
      <div ref={physioRef} id="physiotherapists">
        <PhysiotherapistsContent />
      </div>
      <div ref={contactRef} id="contact">
        <ContactContent />
      </div>
      <div ref={feedbackRef} id="feedback">
        <FeedbackContent />
      </div>
    </div>
  );
}

export default App;
