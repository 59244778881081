import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import "./HomeContentStyles.css";
import logo from '../assets/volverLogo.png'; // Substitua pelo caminho correto para o logotipo
import { FaWhatsapp } from "react-icons/fa";
import usePageTitle from './usePageTitle';

const HomeContent = () => {
    usePageTitle('Volver ProFisio | Fisioterapia & Recovery');

    const cardRefs = useRef([]);

    useEffect(() => {
        cardRefs.current = cardRefs.current.slice(0, 4); // Ajuste o tamanho do array
    }, []);

    return (
        <section id="home">
            <Helmet>
                <title>Volver ProFisio | Fisioterapia & Recovery</title>
                <link rel="canonical" href="https://www.volverprofisio.com.br/" />
                <meta name="description" content="A Volver ProFisio oferece fisioterapia especializada em Uberlândia, com tratamentos personalizados e baseados em evidências científicas para promover sua recuperação completa e bem-estar. Nosso compromisso é fornecer atendimento individualizado, avaliações precisas e terapias avançadas, utilizando as melhores tecnologias disponíveis." />
            </Helmet>
            <div className="home">
                <div className="card-container">
                    {['Equipe Profissional', 'Atendimento individualizado', 'Avaliação Eficaz', 'Investimento em Tecnologia'].map((title, index) => (
                        <div className="card" ref={(el) => (cardRefs.current[index] = el)} key={index}>
                            <h2>{title}</h2>
                            <p>
                                {title === 'Equipe Profissional'
                                    ? 'Somos uma equipe de fisioterapia que possui conhecimentos técnicos atualizados, habilidades interpessoais e compromisso ético. Atuamos com empatia, desenvolvendo planos de tratamento personalizados e com dedicação ao aprimoramento contínuo.'
                                    : title === 'Atendimento individualizado'
                                        ? 'Um fisioterapeuta por paciente com foco em promover a recuperação e a qualidade de vida dos nossos pacientes.'
                                        : title === 'Avaliação Eficaz'
                                            ? 'Garantimos que o tratamento seja direcionado, eficaz e adaptado às necessidades específicas de cada paciente, promovendo a recuperação e a melhora da qualidade de vida.'
                                            : 'Proporcionamos um diagnóstico mais preciso e tratamentos mais eficazes, usamos os melhores equipamentos para oferecer terapias avançadas que aceleram a recuperação. Além de aumentarmos a segurança e o conforto durante o tratamento, melhorando os resultados e a satisfação.'}
                            </p>
                            <img src={logo} alt="Logotipo da empresa" className="logo-overlay" />
                        </div>
                    ))}
                </div>
                <div className="agendar-container">
                    <a href="https://api.whatsapp.com/send/?phone=5534998680500&text=Ol%C3%A1!%20Estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20agendar%20uma%20avalia%C3%A7%C3%A3o.%20Poderia%20me%20fornecer%20mais%20informa%C3%A7%C3%B5es?" target="_blank" rel="noopener noreferrer">
                        <button className="btn"><FaWhatsapp size={20} style={{ marginRight: "0.5rem" }} />Agendar avaliação!</button>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default HomeContent;
