import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './FeedbackStyles.css';

const FeedbackContent = () => {
    useEffect(() => {
        // Adicionar o script do Featurable dinamicamente
        const script = document.createElement('script');
        script.src = 'https://featurable.com/assets/bundle.js';
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            // Limpar o script quando o componente é desmontado
            document.body.removeChild(script);
        };
    }, []);

    return (
        <section id="feedback" className="feedback">
            <Helmet>
                <title>Volver ProFisio | Fisioterapia & Recovery</title>
                <link rel="canonical" href="https://www.volverprofisio.com.br/feedbacks" />
                <meta name="description" content="Veja as avaliações dos clientes da Volver ProFisio e conheça as experiências de quem já passou pelos nossos cuidados especializados." />
            </Helmet>
            <div id="featurable-5fa4e91a-9dcb-44ac-af9f-482d6313902b" data-featurable-async data-location-code="pt-BR"></div>
        </section>
    );
};

export default FeedbackContent;
