// import "./WhatsAppContentStyles.css";
import React, { useEffect } from 'react';

import { FloatingWhatsApp } from 'react-floating-whatsapp';

import avatar from "../assets/logo-original.png";

const WhatsAppContent = () => {
    return (
        <div className="whatsapp">
            <FloatingWhatsApp id="whats" className="whats"
                phoneNumber="+5534998680500"
                accountName="Volver ProFisio"
                statusMessage="Fisioterapia especializada!"
                avatar={avatar}
                chatMessage={"Nossa prioridade é estabelecer sua qualidade de vida com fisioterapia qualificada e especializada.\n\nFale com a gente e venha fazer uma avaliação."}
                placeholder='Digite sua mensagem aqui!'
                messageDelay={0.5}
                darkMode={false}
                allowClickAway={true}
                allowEsc={true}
                notification={true}
                notificationDelay={7}
                chatboxHeight={400}
            />
        </div>
    );
}

export default WhatsAppContent;