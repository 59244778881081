import React from 'react';
import { Helmet } from 'react-helmet';
import { FaHome, FaInstagram, FaMailBulk, FaPhone, FaWhatsapp } from "react-icons/fa";
import "./ContactStyles.css";
import { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

const Contact = () => {    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (successMessage || errorMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                setErrorMessage('');
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [successMessage, errorMessage]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const timer = setTimeout(() => {
                setErrors({});
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [errors]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name' && /[^a-zA-Z\s]/.test(value)) return;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        const errors = {};
        if (formData.name.length < 3) errors.name = "Nome deve ter no mínimo 3 caracteres.";
        if (!/^\S{3,}@\S{3,}\.\S{2,}$/.test(formData.email)) {
            errors.email = "Email inválido. Seguir padrão: aaa@aaa.com";
        }
        if (!/^\(\d{2}\) \d{5}-\d{4}$/.test(formData.phone)) {
            errors.phone = "Telefone inválido. Seguir padrão: (34) 99999-0000";
        }
        if (formData.message.length < 10) errors.message = "Mensagem deve ter no mínimo 10 caracteres";
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate();
        if (Object.keys(errors).length === 0) {
            try {
                const response = await fetch('https://c0vn9i8p7g.execute-api.sa-east-1.amazonaws.com/SendToGoogleForms', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });

                if (response.ok) {
                    setSuccessMessage('Formulário enviado com sucesso!');
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        message: ''
                    });
                } else {
                    setErrorMessage('Falha ao enviar o formulário. Tente novamente mais tarde.');
                }
            } catch (error) {
                console.error('Erro ao enviar o formulário:', error);
                setErrorMessage('Erro ao enviar o formulário. Verifique sua conexão com a internet e tente novamente.');
            }
        } else {
            setErrors(errors);
        }
    };

    return (
        <section id="contact" className="contact">
            <Helmet>
                <title>Volver ProFisio | Fisioterapia & Recovery</title>
                <link rel="canonical" href="https://www.volverprofisio.com.br/contatos" />
                <meta name="description" content="Entre em contato com a Volver ProFisio para agendar sua consulta ou esclarecer dúvidas. Estamos localizados em Uberlândia/MG e prontos para atender você." />
            </Helmet>
            <div className="contact-container">
                <div className="contact-info">
                    <a href="https://g.co/kgs/2Nemizv" target="_blank" rel="noopener noreferrer">
                        <FaHome size={30} className="icon" /> Av. Afonso Pena, 4669 - Bairro Umuarama - Uberlândia/MG
                    </a>
                    <a href="https://api.whatsapp.com/send/?phone=5534998680500&text=Ol%C3%A1!%20Estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20agendar%20uma%20avalia%C3%A7%C3%A3o.%20Poderia%20me%20fornecer%20mais%20informa%C3%A7%C3%B5es?" target="_blank" rel="noopener noreferrer">
                        <FaWhatsapp size={30} className="icon" /> +5534998680500
                    </a>
                    <a href="https://www.instagram.com/volver.profisio" target="_blank" rel="noopener noreferrer">
                        <FaInstagram size={30} className="icon" /> @volver.profisio
                    </a>
                    <a href="tel:034998680500" target="_blank" rel="noopener noreferrer">
                        <FaPhone size={30} className="icon" /> (34) 99868-0500
                    </a>
                    <p><FaMailBulk size={30} className="icon" /> volverprofisio@gmail.com</p>
                </div>
                <div className="form-container">
                    {errorMessage && <div className="error balloon">{errorMessage}</div>}
                    {successMessage && <div className="success balloon">{successMessage}</div>}
                    {Object.keys(errors).length > 0 && (
                        <div className="error balloon">
                            {Object.values(errors).map((error, index) => (
                                <p key={index}>{error}</p>
                            ))}
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="name">Nome</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Digite seu nome..."
                            required
                        />
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Digite seu email..."
                            required
                        />
                        <label htmlFor="phone">Telefone</label>
                        <InputMask
                            mask="(99) 99999-9999"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Digite seu telefone..."
                        >
                            {(inputProps) => (
                                <input
                                    {...inputProps}
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    required
                                />
                            )}
                        </InputMask>
                        <label htmlFor="message">Mensagem</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Deixe sua mensagem para Volver ProFisio."
                            required
                        ></textarea>
                        <button type="submit">Enviar</button>
                    </form>
                </div>
            </div>
            <div className="map-wrapper">
                <div className="map-container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15100.056494860704!2d-48.2543598!3d-18.8864546!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a44534608ce1c9%3A0x7b67356277a39511!2sVolver%20ProFisio!5e0!3m2!1spt-BR!2sbr!4v1721408314185!5m2!1spt-BR!2sbr"
                        width="800"
                        height="305"
                        allowFullScreen=""
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </section>
    );
};

export default Contact;
